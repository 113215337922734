<template>
    <div>
        <div class="analytic">
            <template v-if="isEmpty">
                {{ formatEmptyString() }}
            </template>
            <template v-else>
                <div
                    :key="index"
                    v-for="(cells, index) of dataCellsAnalytic"
                    class="has-text-centered is-justify-content-center my-1">
                    <Component
                        :is="cells.tag ? cells.tag : 'div'"
                        :type="cells.type">
                        <template v-for="(cell, index) of cells.body">
                            <LTooltip
                                :key="index"
                                :label="cell.title"
                                :type="cell.tooltipType || cells.tooltipType">
                                <span
                                    v-if="!item.isTotalColumn || !cell.hideField"
                                    :class="cell.classList">
                                    {{ cell.value }}
                                </span>
                                <template v-if="cell.differenceIndicator && !item.isTotalColumn">
                                    <b-icon
                                        v-if="difference > 0"
                                        class="has-text-success"
                                        icon="arrow-up">
                                    </b-icon>
                                    <b-icon
                                        v-else-if="difference < 0"
                                        class="has-text-danger"
                                        icon="arrow-down">
                                    </b-icon>
                                    <b-icon
                                        v-else
                                        icon="minus"
                                        size="is-small">
                                    </b-icon>
                                </template>
                            </LTooltip>

                            <span
                                v-show="cell.showSeparator"
                                :key="cell.title"
                                :class="[cell.classList, 'mx-1']">
                                {{ cells.separator }}
                            </span>
                        </template>
                    </Component>
                </div>
            </template>
        </div>

        <div
            v-if="!item.isTotalColumn && item.isIncidence"
            class="custom-button-wrapper">
            <LTooltip
                :label="commentButtonTooltip"
                :type="item.hasIncidence ? 'is-info' : 'is-success'">
                <b-button
                    :icon-left="item.hasIncidence ? 'comment-verify' : 'comment-plus'"
                    :disabled="!canAddReason"
                    icon-pack="uil"
                    size="is-small"
                    type="is-light"
                    class="custom-button is-info"
                    :class="{ 'has-incidence is-success': !item.hasIncidence }"
                    @click="openCommentModal">
                </b-button>
            </LTooltip>
        </div>
    </div>
</template>



<script >
import { useReasonModal } from "@/stores/admin/analytic/subAnalytic/reasonModalStore";
import { defineProps } from "@vue/runtime-dom";
import { storeToRefs } from "pinia";
import { StatisticTableViewAnalytic } from "@core/mixins/statistic/statisticTableViewAnalytic";
import Permissions from "@/components/Common/Permissions.vue";
import { hasPermissions } from "@core/mixins/permissions";
import { mapState } from "vuex";
const __sfc_main = {
  name: "StatisticTableAnalyticAnalyseColumn",
  mixins: [StatisticTableViewAnalytic],
  components: {
    Permissions
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    uniqueKey: {
      type: String,
      default: null
    },
    group: {
      type: String,
      default: null
    },
    groupField: {
      type: null,
      default: null
    }
  },
  computed: {
    ...mapState({
      hasIncidenceAddAll: ({
        auth
      }) => hasPermissions(["INCIDENCE.ADD.ALL"], auth.userPermissions),
      hasIncidenceAddOwn: ({
        auth
      }) => hasPermissions(["INCIDENCE.ADD.OWN"], auth.userPermissions)
    }),
    ...mapState("admin/analytic/subAnalytic", {
      subAnalyticFilters(state) {
        return state.subAnalytic[this.uniqueKey]?.filters;
      }
    }),
    canAddReason() {
      if (!this.hasIncidenceAddAll) {
        return this.hasIncidenceAddOwn ? this.item.hasOurWebmaster : false;
      }
      return true;
    },
    commentButtonTooltip() {
      const {
        incidence
      } = this.item;
      const path = "common.entity.incidenceReason";
      if (this.canAddReason) {
        return this.$t(`${path}.${incidence?.reason || "add"}`);
      } else {
        if (this.hasIncidenceAddOwn) {
          return this.$t(`${path}.${incidence?.reason || "notMyWebmaster"}`);
        } else {
          return this.$t(`${path}.${incidence?.reason || "empty"}`);
        }
      }
    },
    difference() {
      return this.item.difference;
    },
    differenceStatus() {
      const value = this.difference;
      if (value > 0) {
        return "success";
      } else if (value < 0) {
        return "danger";
      } else {
        return "dark";
      }
    },
    dataCellsAnalytic() {
      return [{
        separator: "/",
        tooltipType: "is-dark",
        body: [{
          classList: "has-text-weight-bold",
          title: this.$t("admin.statistics.analytics.table.values.cell.leadsIntegrated"),
          value: this.leadsIntegrated,
          showSeparator: false
        }, {
          tooltipType: `is-${this.differenceStatus}`,
          classList: `difference ml-2 has-text-${this.differenceStatus}`,
          title: this.$t("admin.statistics.analytics.table.values.cell.leadDifference"),
          value: `(${this.item.differencePercent}%)`,
          differenceIndicator: true,
          showSeparator: false,
          hideField: true
        }]
      }, {
        tag: "b-tag",
        type: "is-success",
        tooltipType: "is-success",
        separator: "|",
        body: [{
          title: this.$t("admin.statistics.analytics.table.values.cell.commonApprove"),
          value: this.commonApprove,
          showSeparator: false
        }]
      }, {
        separator: "|",
        tooltipType: "is-danger",
        body: [{
          classList: "has-text-danger",
          title: this.$t("admin.statistics.analytics.table.values.cell.leadSpamByAdvertiser"),
          value: this.leadSpamByAdvertiser,
          showSeparator: false
        }]
      }];
    }
  }
};
__sfc_main.props = {
  item: {
    type: Object,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const storeModal = useReasonModal();
  const {
    isActive,
    input
  } = storeToRefs(storeModal);
  const props = __props;
  function openCommentModal() {
    const {
      day: date,
      offerId,
      webmasterId,
      incidence,
      group: subsection,
      hasOurWebmaster,
      uniqueKey
    } = props.item;
    isActive.value = true;
    input.value = {
      uniqueKey,
      subsection,
      date,
      offerId,
      webmasterId,
      hasOurWebmaster,
      reason: incidence?.reason
    };
  }
  return {
    openCommentModal
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
    $color-success: #00855B !important;
    $background-button: #fff !important;
    $color-button: #FF5866 !important;
    $analytic-width: 13rem;

    ::v-deep {
        .custom-button-wrapper {
            position: absolute;
            top: 0;
            right: 0;
        }

        .custom-button {
            background-color: $background-button;
            border-radius: 0 0 0 7px !important;
        }
    }

    .analytic {
        min-width: $analytic-width;
        position: relative;

        & > * {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
    }

    .tag {
        &.is-success {
            color: $color-success;
            background: #E3F8EF;
        }
    }

    .difference.has-text-success {
        color: $color-success;
    }
</style>
